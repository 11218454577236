export const environment = {
  production: true,
  endpoint: 'https://letsgo.casago.com/api',
  S3_BUCKET_URL: "https://casago-casa-app.s3.us-west-2.amazonaws.com",
  S3_BUCKET: "casago-casa-app",
  S3_ROOT: "prod/v2/",
  AWS_ACCESS_KEY_ID: "AKIAXOWZY22ABBPJ5MEU",
  AWS_SECRET_ACCESS_KEY: "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",
  AWS_REGION: "us-west-2",
  MODE: "desktop",
  firebaseConfigDesktop: {
    apiKey: "AIzaSyA9Xh2rT2PU5HuDEec0PCBC0lXEqjdDylg",
    authDomain: "letsgo-app-8f971.firebaseapp.com",
    projectId: "letsgo-app-8f971",
    storageBucket: "letsgo-app-8f971.appspot.com",
    messagingSenderId: "1071274093713",
    appId: "1:1071274093713:web:50a00a8bf130c72db06330",
    vapidKey: "BKkPRrtv_hfbI9bKI6HuLtLaEh9Emv3r17MwqH-jqWplTI4t_vRYVJKdARfpWCf8_5EjHaYLOJuQoTfWPBUPPXs"
  }
};