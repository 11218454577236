import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, of, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { SharedService } from '../shared/shared.service';
import { LoggedUser } from './auth.interfaces';
import { Preferences } from '@capacitor/preferences';
import { User, UserLanguage, UserRole } from '../desktop/management/users/users.interfaces';
import { UsersService } from '../desktop/management/users/users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: BehaviorSubject<LoggedUser | null> = new BehaviorSubject<LoggedUser | null>(null);
  currentUser$ = new BehaviorSubject<User | null>(null);
  desktop_main_page = ['/', 'desktop', 'home'];
  mobile_main_page = ['/', 'mobile', 'home'];
  desktop_main_page_url = '/desktop/home';
  mobile_main_page_url = '/mobile/home';
  strosAccess$ = new BehaviorSubject<boolean>(false);
  lang$ = new BehaviorSubject<UserLanguage>(UserLanguage.ENGLISH);

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    private sharedService: SharedService
  ) { }

  signin(email: string, password: string): Observable<User> {
    const isMobile = this.sharedService.isMobile$.getValue();
    return this.http.post<User>(
      `${environment.endpoint}/auth/signin`,
      {
        email, password, isMobile
      },
      {
        headers: new HttpHeaders({
          'Content-Type' : 'application/json'
        })
      }
    ).pipe(
      catchError(this.sharedService.handleRequestError)
    )
  }

  whoami(): Observable<User> {
    return this.http.get<User>(
      `${environment.endpoint}/auth/whoami`,
      {
        headers: this.sharedService.noCacheHeaders
      }
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  updateLoggedUser(user: LoggedUser) {
    return this.storeAuthData(user);
  }

  recoverSession(){
    return this.getStoredAuthData();
  }

  initCurrentUser(): Observable<User | null> {
    const user = this.currentUser$.getValue();
    if (user) {
      return of(user);
    }
    const loggedUser = this.user$.getValue();
    if (loggedUser) {
      return this.usersService.getUser(loggedUser.id);
    }
    return of(null);
  }

  logout(frontToken: string, removeToken = true) {
    return this.signOut(frontToken, removeToken).pipe(
      switchMap(() => {
        return this.removeStoredAuthData();
      }),
      catchError(this.sharedService.handleRequestError),
      tap(() => {
        this.user$.next(null);
      })
    )
  }

  signOut(frontToken:string, removeToken = false) {
    if (removeToken) {
      return this.http.post<any>(
        `${environment.endpoint}/auth/signout`, {frontToken}
      );
    }
    return of(null);
  }

  parseToLoggedUser(user: User, photo?: string, token?: string, isFake?: boolean): LoggedUser {
    const loggedUser: LoggedUser = {
      id: user.id,
      name: this.usersService._getShortName(user),
      email: user.email,
      role: user.role,
      job: user.job,
      branches: user.branches,
      access: user.access || user.job?.access || [],
      special_access: user.special_access || [],
      token: token || user.token__ || "",
      photo: photo || user.profile?.photo,
      lang: user.lang,
      fcm_token: user.fcm_token,
      fcm_token_desktop: user.fcm_token_desktop,
      hasTeams: !!user.teams && user.teams.length > 0,
      isFake,
      meta: user.meta
    };
    if (loggedUser.meta) {
      if (loggedUser.meta.tasks_team_view_preferences) {
        loggedUser.meta._tasks_team_view_preferences = JSON.parse(loggedUser.meta.tasks_team_view_preferences);
      }
    }
    return loggedUser;
  }

  getModuleAccess(pageKey: string, moduleKey: string, user: LoggedUser | null | undefined) {
    const defaults = this.getDefaultAccess(user);
    let moduleAccess;
    if (defaults[pageKey] && defaults[pageKey].children[moduleKey]) {
      moduleAccess = defaults[pageKey].children[moduleKey].children;
    }

    if (user && moduleAccess) {
      const fullAccess = this.isSuper(user);
      const userAccess = user.access || [];

      for (let access in moduleAccess) {
        moduleAccess[access].value = fullAccess || userAccess.includes(access);
      }
    }

    return moduleAccess;
  }

  sendVerificationCode(email: string) {
    return this.http.post<any>(
      `${environment.endpoint}/auth/recovery`, {email}
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

 verifyCode(email: string, code: number) {
    return this.http.post<any>(
      `${environment.endpoint}/auth/recovery/verify`, {email, code}
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  recoverPwd(email: string, code: number, pwd: string) {
    return this.http.post<any>(
      `${environment.endpoint}/auth/recovery/change`, {email, code, pwd}
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  updateFcm(fcmToken: string): Observable<string> {
    return this.http.patch<any>(
      `${environment.endpoint}/auth/fcm`, {fcm: fcmToken}
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  updateDesktopFcm(fcmToken: string): Observable<any> {
    return this.http.patch<any>(
      `${environment.endpoint}/auth/fcm/desktop`, {fcm: fcmToken}
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  getToken(user_id: number): Observable<any> {
    return this.http.get<any>(
      `${environment.endpoint}/auth/user/${user_id}/token`
    ).pipe(
      catchError(this.sharedService.handleRequestError),
    )
  }

  isSuper(user: LoggedUser | User | null | undefined) {
    if (user) {
      return user.role === UserRole.SUPER || user.role === UserRole.ADMIN;
    }
    return false;
  }

  hasAccess(access: string, user?: LoggedUser | User): boolean {
    if (user) {
      if (this.isSuper(user)) {
        return true;
      }
      const userAccess = user.access || user.job?.access || [];
      return userAccess.includes(access) ||
        (user.special_access || []).includes(access);
    }
    return false;
  }

  getDefaultAccess(user?: LoggedUser | null | undefined): any {
    let defaultValue = false;
    if (user && this.isSuper(user)) {
      defaultValue = true;
    }
    const userAccess: any = {
      'management': {
        children: {
          'manage-users': {
            label: 'Users Management',
            children: {
              'manage-users-view': { label: 'View users list', value: defaultValue },
              'manage-users-add': { label: 'Add new users', value: defaultValue },
              'manage-users-edit': { label: 'Edit users', value: defaultValue },
              'manage-users-pwd': { label: 'Update users password', value: defaultValue },
              'manage-users-access': { label: 'Update users access', value: defaultValue },
              'manage-users-status': { label: 'Update users status', value: defaultValue }
            }
          },
          'manage-jobs': {
            label: 'Jobs Management',
            children: {
              'manage-jobs-view': { label: 'View jobs list', value: defaultValue },
              'manage-jobs-add': { label: 'Add new jobs', value: defaultValue },
              'manage-jobs-edit': { label: 'Edit jobs', value: defaultValue },
              'manage-jobs-delete': { label: 'Delete jobs', value: defaultValue },
              'manage-jobs-access': { label: 'Update jobs access', value: defaultValue }
            }
          },
          'manage-branches': {
            label: 'Branches Management',
            children: {
              'manage-branches-view': { label: 'View branches list', value: defaultValue },
              'manage-branches-add': { label: 'Add new branches', value: defaultValue },
              'manage-branches-edit': { label: 'Edit branches', value: defaultValue },
              'manage-branches-delete': { label: 'Delete branches', value: defaultValue }
            }
          },
          'manage-teams': {
            label: 'Teams Management',
            children: {
              'manage-teams-own': { label: 'Manage and create own teams', value: defaultValue },
              'manage-teams-lead': { label: 'Capability to be teams leader', value: defaultValue }
            }
          },
          'manage-projects': {
            label: 'Project Management',
            children: {
              'manage-projects-own': { label: 'Manage and create own project', value: defaultValue }
            }
          }
        },
        allowed: defaultValue
      },
      'gbu': {
        children: {
          'manage-gbu': {
            label: 'GBU Module',
            children: {
              'manage-gbu-send': { label: 'Send assigned GBU reports', value: defaultValue },
              'manage-gbu-view': { label: 'View followed reports', value: defaultValue },
              'manage-gbu-admin': { label: 'Create GBU reports', value: defaultValue }
            }
          }
        }
      },
      'receipts': {
        children: {
          'manage-receipts': {
            label: 'Manage Expenses',
            children: {
              'manage-receipts-view': { label: 'View expenses list', value: defaultValue },
              'manage-receipts-add': { label: 'Add new expense receipt', value: defaultValue },
              'manage-receipts-edit': { label: 'Edit expense receipts', value: defaultValue },
              'manage-receipts-admin': { label: 'Admin access to expenses', value: defaultValue }
            }
          }
        }
      },
      'gas': {
        children: {
          'manage-gas': {
            label: 'Manage Fleet Receipts (Gas)',
            children: {
              'manage-gas-view': { label: 'View fleet receipt list', value: defaultValue },
              'manage-gas-add': { label: 'Add new fleet receipt', value: defaultValue },
              'manage-gas-edit': { label: 'Edit fleet receipts', value: defaultValue },
              'manage-gas-admin': { label: 'Admin access to fleet receipts & vehicle profiles', value: defaultValue }
            }
          },
          'manage-vehicles': {
            label: 'Manage Vehicle Profiles',
            children: {
              'manage-vehicles-view': { label: 'View vehicle profile list', value: defaultValue },
              'manage-vehicles-add': { label: 'Add new vehicle profile', value: defaultValue },
              'manage-vehicles-edit': { label: 'Edit vehicle profiles', value: defaultValue },
              'manage-vehicles-delete': { label: 'Delete vehicle profiles', value: defaultValue },
              'manage-vehicles-services': { label: 'View and add oil changes', value: defaultValue }
            }
          }
        }
      },
      'tasks': {
        children: {
          'manage-tasks': {
            label: 'Tasks Module',
            children: {
              'manage-tasks-editor': { label: 'Access to update any task', value: defaultValue }
            }
          }
        }
      },
      'goals': {
        children: {
          'manage-goals': {
            label: 'STROS Module (Goals)',
            children: {
              'manage-goals-own': { label: 'View and create own road maps', value: defaultValue }
            }
          }
        }
      },
      'meetings': {
        children: {
          'manage-meetings': {
            label: 'Meetings Module',
            children: {
              'manage-meetings-all': { label: 'View and create meetings', value: defaultValue },
              'manage-meetings-settings': { label: 'Manage meetings (admin)', value: defaultValue },
              //'manage-meetings-reports': { label: 'View meetings history report', value: defaultValue }, only superusers will have access to this
              'manage-meetings-schedule': { label: 'Create scheduled meetings', value: defaultValue }
            }
          }
        }
      },
      'kpis': {
        children: {
          'manage-kpis': {
            label: 'KPIs Boards Module',
            children: {
              'manage-kpis-view': { label: 'View and fill assigned KPIs', value: defaultValue },
              'manage-kpis-create': { label: 'Create and edit KPIs', value: defaultValue },
              'manage-kpis-boards': { label: 'Manage KPI boards', value: defaultValue }
            }
          }
        }
      },
      'forms': {
        children: {
          'manage-forms': {
            label: 'Forms Module',
            children: {
              'manage-forms-view': { label: 'View and take global and assigned forms', value: defaultValue },
              'manage-forms-create': { label: 'Can create, edit and delete owned forms', value: defaultValue },
              'manage-forms-administrated': { label: 'Can edit administrated forms', value: defaultValue },
              'manage-forms-admin': { label: 'Can manage forms from assigned branches', value: defaultValue }
            }
          }
        }
      },
      'leaves': {
        children: {
          'manage-leaves': {
            label: 'Leave Tracker Module',
            children: {
              'manage-leaves-calendar': { label: 'View leaves calendar (assigned branches/teams)', value: defaultValue },
              'manage-leaves-settings': { label: 'Manage the leave options for assigned branches', value: defaultValue },
              'manage-leaves-hr': { label: 'HR access to register vacations, manage days, etc.', value: defaultValue }
            }
          }
        }
      },
      'org': {
        children: {
          'manage-org': {
            label: 'Organizational Chart',
            children: {
              'manage-org-full': { label: 'Access to full organization chart', value: defaultValue }
            }
          }
        }
      },
      'history': {
        children: {
          'manage-history': {
            label: 'History',
            children: {
              'manage-history-full': { label: 'View History', value: defaultValue }
            }
          }
        },
        allowed: defaultValue
      }
    };
    return userAccess;
  };

  getSpecialDefaultAccess() {
    const access: any = {
      'management': {
        label: 'Management',
        children: {
          // 'manage-tasks-projects': {
          //   label: 'Manage projects',
          //   value: false
          // },
          'manage-branches-us': {
            label: 'Grant access to all US branches',
            value: false
          },
          'manage-branches-mx': {
            label: 'Grant access to all MX branches',
            value: false
          }
        }
      },
      'meetings': {
        label: 'Meetings Module',
        children: {
          'manage-meetings-externalvideos': {
            label: 'Manage external meeting videos (folders)',
            value: false
          }
        }
      },
      'shared-branch-info': {
        label: 'Public nformation',
        children: {
          'manage-shared-branch-info': {
            label: 'Edit public branch information',
            value: false
          }
        }
      },
      'tasks': {
        label: 'Tasks Module',
        children: {
          'manage-task-teams': {
            label: 'Access to see all teams on tasks module',
            value: false
          },
          'manage-task-teams-view': {
            label: 'Access to the management teams view',
            value: false
          },
          'manage-task-report-team': {
            label: 'Access to generate tasks report',
            value: false
          }
        }
      },
      'knowledge': {
        label: 'Knowledge Base Module',
        children: {
          'manage-knowledge-admin': {
            label: 'Create and edit knowledge base articles',
            value: false
          }
        }
      },
      'userguide': {
        label: 'User Guide Module',
        children: {
          'manage-userguide-admin': {
            label: 'Create and edit user guide chapters',
            value: false
          }
        }
      },
      'training': {
        label: 'Training Module',
        children: {
          'manage-training-teacher': {
            label: 'Rate and see records of assigned courses',
            value: false
          },
          'manage-training-admin': {
            label: 'Access to manage all the training module',
            value: false
          }
        }
      },
    }
    return access;
  }

  hasSharedBranchAccess(user: LoggedUser) {
    const hasJobAccess = user?.job.name.indexOf("branch/franchise owner") !== -1
      || user?.job.name.indexOf("branch manager") !== -1
      || user?.job.name.indexOf("general manager") !== -1;
    return this.hasAccess("manage-shared-branch-info", user)
      || this.isSuper(user)
      || hasJobAccess;
  }

  private removeStoredAuthData() {
    return Preferences.remove({key: 'authData'});
  }

  private async getStoredAuthData(): Promise<LoggedUser | null> {
    const data = await Preferences.get({key: 'authData'});
    let loggedUser: LoggedUser | null = null;
    if (data && data.value) {
      loggedUser = JSON.parse(data.value);
    }
    if (loggedUser?.lang) {
      this.lang$.next(loggedUser.lang);
    }
    return loggedUser;
  }

  private async storeAuthData(loggedUser: LoggedUser)  {
    if (loggedUser?.lang) {
      this.lang$.next(loggedUser.lang);
    }
    await Preferences.set({key: 'authData', value: JSON.stringify(loggedUser)});
    return loggedUser;
  }
}
